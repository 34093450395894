import React from "react"
import Layout from "../components/layout"
import Button from "@bit/azheng.joshua-tree.button"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { SocialIcon } from "../components/SocialReviews"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

import "../css/pages/_shoes.scss"

const ShoePage = () => {
  const steps = [
    {
      heading: "",
      text: "After April 15th, we'll post our top submissions in the office and on social media. Vote for your favorite design by commenting on our social post and with your followers for an extra vote!\n\nIf you win, you'll come to the office, collaborate with Dr. Stevens to bring your awesome design to life, and create YOUR OWN custom pair of Nikes! We'll buy the shoes. All you have to do is swing by to grab your fresh kicks and strut around town in style!",
      hasSocials: true
    }
  ]
  return (
    <SharedStateProvider>
      <Layout language={"en"}>
        <SEO
          title="Nike Shoe Design Contest"
          description="Braces Omaha is hosting a shoe designing contest. Win a pair of custom shoes!"
          lang={"en"}
        />

        <div className="shoe">
          <div className="shoe__hero">
            <div className="shoe__container">
              <div className="shoe__hero--art">
                <h1>
                  Step Into
                  <br />
                  Creativity
                  <br />& Just Do It
                  <br />
                  with Style!
                </h1>
                <img
                  src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/nikeshoe_graphic"
                  alt="design of nike sneaker"
                />
              </div>
              <div className="shoe__hero--content">
                <h2>SHOE DESIGNING CONTEST | WIN A PAIR OF CUSTOM SHOES</h2>
                <h3>Thank you for your submissions!</h3>
              </div>
            </div>
          </div>

          {steps.map((step, index) => (
            <div
              key={index}
              className={`shoe__section ${
                index % 2 === 0 ? "color-back" : ""
              }`}>
              <div className="shoe__container">
                <div className="shoe__section--content has-text-centered">
                  {step.heading && <h4>{step.heading}</h4>}

                  <MarkdownViewer markdown={step.text} />
                  {step.button && (
                    <Button
                      buttonText={step.button.text}
                      href={step.button.href}
                      contained
                      external={step.button.destination === "external"}
                    />
                  )}
                  {step.hasSocials && <Socials />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default ShoePage

const Socials = () => {
  return (
    <div className="shoe__socials">
      <a
        href="https://www.facebook.com/bracesomaha/"
        target="_blank"
        title="Follow us on Facebook">
        <SocialIcon social={{ platform: "Facebook" }} />
      </a>
      <a
        href="https://www.instagram.com/braces_omaha/"
        target="_blank"
        title="Follow us on Instagram">
        <SocialIcon social={{ platform: "Instagram" }} />
      </a>
    </div>
  )
}
